import checkbox from './checkbox'
import date from './date'
import list from './list'
import radio from './radio'
import select from './select'
import struct from './struct'
import textbox from './textbox'

export default {
  checkbox,
  date,
  list,
  radio,
  select,
  struct,
  textbox
}
